import React, { useEffect } from "react";
import { motion } from "framer-motion";
import "./App.css";

const App = () => {
  return (
    <div className="app">
      <header className="header">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>SWOP</h1>
          <p className="subtitle">Веб-учёт предприятия</p>
        </motion.div>
      </header>

      <section className="companies-grid">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="grid-container"
        >
          <div className="company-card">
            <h3>Версия предварительного тестирования</h3>
            <p className="version-info">
              <span className="old-version">version 0.0.1-a1</span>
              <span className="version-arrow">→</span>
              <span className="new-version">version 0.0.1-a3</span>
            </p>
            <div className="button-group">
              <a href="http://alphatest.swop.su/" className="cta-button">
                Перейти
              </a>
              <button
                className="cta-button disabled"
                disabled
                title="Архив пока пуст"
              >
                Архив версий
              </button>
            </div>
          </div>
        </motion.div>
      </section>

      <section className="info-section">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          className="info-container"
        >
          <div className="info-content">
            <h2>Демоверсия SWOP</h2>
            <p className="author-info">
              Разработано{" "}
              <a href="http://cathev.ru/" className="author-link">
                Cathev
              </a>{" "}
              by Евгений Кузнецов
            </p>
          </div>
        </motion.div>
      </section>

      <footer className="footer">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="footer-content"
        >
          <div className="footer-info">
            <h3>SWOP</h3>
            <p>Система веб-учёта предприятия</p>
          </div>
          <div className="footer-links">
            {/*<a href="http://cathev.ru/">О компании</a>*/}
            <a href="http://alphatest.swop.su/">Демо-версия</a>
            <a href="https://t.me/gennadiius">Связаться с нами</a>
          </div>
          <div className="footer-copyright">
            <p>
              &copy; 2024{" "}
              <a href="http://cathev.ru/" className="author-link">
                Cathev
              </a>
            </p>
          </div>
        </motion.div>
      </footer>
    </div>
  );
};

export default App;
